body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-calendar-picker-indicator
{
  filter: invert(1)
  brightness(10%)
  sepia(100%)
  saturate(10000%)
  hue-rotate(330deg)
}

a{
  text-decoration: none;
  position: relative;
}

.activeNavbar::after{
  content: '';
  position: absolute;
  height:2px;
  width: 100%;
  background-color: #E91E63;
  left:0;
  bottom:1px;
  opacity:0;
  transition: all 0.2s;
  pointer-events: none;
}
.activeNavbar.active::after{
  opacity: 1;
}
.activeNavbar.active{
  background-color: #36262C;
}
.activeNavbar:hover::after{
  opacity:1;
}